<template>
  <div ref="scrollContainer" class="flex flex-col overflow-x-auto mb-3 hide-scrollbar width-full">
    <div v-for="item in getFormattedArr" :key="item" :style="{ gap: data?.margin == null ? 0 : `${data?.margin}px` }"
         class="flex items-center width-full">
      <nuxt-link v-for="(image, index) in item" :key="`grid-image-${index}`"
                 :style="{ minWidth: `${image?.width}px`, maxWidth: `${image?.width}px` }"
                 :to="formattedSeoUrl(image)"
                 @click.prevent="toAuthentication(image)"
      >
        <niceone-image :src="image.image_url" class="rounded-md w-full"/>
      </nuxt-link>
    </div>
  </div>

  <div v-if="getFormattedArr?.length && data?.scrollbar"
       class="w-12 h-1 my-3 mx-auto overflow-hidden rounded-lg bg-border-light flip-icon">
    <div class="relative my-0 mx-1 h-1">
      <div :style="{ left: `${getScrollPercentage}%`, transition: 'all' }"
           class="rounded-lg w-2 absolute h-full -ml-1 bg-primary"></div>
    </div>
  </div>
</template>

<script setup>
import {useAuth} from '@/composables/useAuth'
import {BANNER_TYPE_FOR_AUTH} from '@/constants'
import {formattedSeoUrl} from "@/utilities";

const props = defineProps({
  data: [Object],
  loading: {
    type: Boolean,
    default: false
  },
})

const maxItemsInRow = computed(() => props.data?.max_items_in_row || 5)

const slider = computed(() => {
  return props.data.data.filter((img) => {
    if (!useNuxtApp().$device.isDesktop) return true
    return !img.for_mobile && useNuxtApp().$device.isDesktop;
  })
})

const getFormattedArr = computed(() => {
  return slider.value.reduce((acc, item, index) => {
    if (index % maxItemsInRow.value === 0) acc.push([]);
    acc[acc.length - 1].push(item);
    return acc;
  }, []);
})

const scrollContainer = ref(null)
const {x: scrollX} = useScroll(scrollContainer, {behavior: 'smooth'})

const {locale} = useI18n()

const getScrollPercentage = computed(() => {
  const scrollContainerEl = scrollContainer.value;
  if (!scrollContainerEl) return 0;

  const totalScrollableWidth = scrollContainerEl.scrollWidth - scrollContainerEl.clientWidth;

  const percentage = (getScrollValue.value / totalScrollableWidth) * 100;
  return Math.min(Math.max(percentage, -5), 105) || 0
})

const getScrollValue = computed(() => {
  if (locale.value === 'en') return scrollX.value

  // Revert values for IOS
  return scrollX.value > 0 ? -scrollX.value : Math.abs(scrollX.value);
})

const {useRedirection} = useAuth()
const toAuthentication = (item) => {
  const type = parseInt(item?.type)
  if (type === BANNER_TYPE_FOR_AUTH) {
    useRedirection(item)
  }
}
</script>